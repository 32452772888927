// extracted by mini-css-extract-plugin
export var ContactForm = "ContactForm-module--ContactForm--65d21";
export var box = "ContactForm-module--box--JDgGJ";
export var box1Of1 = "ContactForm-module--box1Of1--dt9j7";
export var box1Of2 = "ContactForm-module--box1Of2--FG2ge";
export var box1Of3 = "ContactForm-module--box1Of3---JC0s";
export var box1Of4 = "ContactForm-module--box1Of4--pX-Dk";
export var box2Of2 = "ContactForm-module--box2Of2--FRoiy";
export var box2Of3 = "ContactForm-module--box2Of3--ZAETK";
export var box2Of4 = "ContactForm-module--box2Of4--FixUD";
export var box3Of3 = "ContactForm-module--box3Of3--tKK-l";
export var box3Of4 = "ContactForm-module--box3Of4--i3zgt";
export var box4Of4 = "ContactForm-module--box4Of4--us3MN";
export var phoneContact = "ContactForm-module--phoneContact--5Z+CF";
import React from 'react';
import PropTypes from 'prop-types';
import DefaultLayout from '../layout/DefaultLayout';
import ProductDetail from '@mangoart/gatsby-ui/components/ezagrar/ProductDetail';
import { graphql } from 'gatsby';

ProductGroupTemplate.propTypes = {
  data: PropTypes.shape().isRequired,
};

function ProductGroupTemplate({ data, pageContext }) {
  const { products } = data;
  const { manufacturerId, productGroupId } = pageContext;

  const manufacturers = {};
  const productGroups = {};
  const groupedProducts = {};

  products.edges.forEach(({ node }) => {
    if (node && node.productGroup) {
      const productGroup = node.productGroup;
      const manufacturer = productGroup.manufacturer;

      manufacturers[manufacturer.id] = manufacturer;
      productGroups[productGroup.id] = productGroup;

      groupedProducts[manufacturer.id] = groupedProducts[manufacturer.id] || {};
      groupedProducts[manufacturer.id][productGroup.id] = groupedProducts[manufacturer.id][productGroup.id] || [];
      groupedProducts[manufacturer.id][productGroup.id].push(node);
    }
  });

  const selectedManufacturer = manufacturers[manufacturerId];
  const selectedProductGroup = productGroups[productGroupId];
  const selectedProducts = groupedProducts[manufacturerId][productGroupId];

  return (
    <DefaultLayout>
      <ProductDetail
        data={{ manufacturer: selectedManufacturer, productGroup: selectedProductGroup, products: selectedProducts }}
      />
    </DefaultLayout>
  );
}

export default ProductGroupTemplate;

export const query = graphql`
  query ProduktgruppenSeiten {
    products: allCockpitProdukte {
      edges {
        node {
          id
          name
          link
          models
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          productGroup {
            id
            name
            published
            description
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            manufacturer {
              id
              name
              image {
                childImageSharp {
                  gatsbyImageData(width: 1600)
                }
              }
              logopng {
                publicURL
                childImageSharp {
                  gatsbyImageData(height: 100, transformOptions: { fit: CONTAIN })
                }
              }
              logojpg {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;

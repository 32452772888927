import React from 'react';

import * as styles from './Description.module.css';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Button } from '../Button';
import { Icon } from '../Icon';

function Description({ node, logo, t, locale }) {
  const { name, site, description } = node;

  return (
    <div className={styles.content}>
      <div className={styles.logo}>
        {logo ? <GatsbyImage image={logo} imgStyle={{ objectFit: 'contain' }} /> : null}
      </div>
      <div className={styles.container}>
        <div className={styles.left}>
          <h2>{name}</h2>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <div className={styles.right}>
          {!!site ? (
            <Button to={site} color="blue">
              <Icon icon={'arrow-right'} />
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Description;

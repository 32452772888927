import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import ContactFormImpl from '@mangoart/gatsby-ui/components/ezagrar/ContactForm';
import InnerForm from '@mangoart/gatsby-ui/components/ezagrar/ContactForm/InnerFormProdukte';

import * as styles from './ContactForm.module.css';
import { Icon } from '../Icon';

ContactForm.propTypes = {
  cols: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};
ContactForm.defaultProps = {};

function ContactForm({ cols, width }) {
  return (
    <div className={clsx(styles.ContactForm, styles.box, styles[`box${width}Of${cols}`])}>
      <h2>Haben Sie Fragen?</h2>
      <h3>Senden Sie uns eine E-Mail oder rufen Sie uns an.</h3>
      <span className={styles.phoneContact}>
        <Icon icon={'phone'} /> 0732/341144-0
      </span>
      <ContactFormImpl form={InnerForm} />
    </div>
  );
}

export default ContactForm;

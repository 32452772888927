import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'gatsby';
import * as styles from './InnerFormProdukte.module.css';

InnerForm.propTypes = {};
InnerForm.defaultProps = {};

const submitFeedback = (status) => {
  if (status === 'submitted') {
    return (
      <div className={clsx(styles.formControl, styles.formFeedback)}>Ihre Anfrage wurde erfolgreich abgeschickt!</div>
    );
  }
  if (status === 'error') {
    return (
      <div className={clsx(styles.formControl, styles.formFeedback, styles.error)}>
        Es kam leider zu einem Fehler beim Absenden des Formulars!
      </div>
    );
  }
  return null;
};

function InnerForm(props) {
  const { values, touched, errors, status, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset } =
    props;

  return (
    <form onSubmit={handleSubmit} className={clsx(styles.ContactForm, styles[values.layout || 'small'])}>
      <div className={styles.column}>
        <div className={styles.formControl}>
          <input
            id="email"
            placeholder="Ihre E-Mail Adresse"
            aria-label="Ihre E-Mail Adresse"
            aria-required="true"
            autoComplete="email"
            type="text"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.email && touched.email ? 'text-input error' : 'text-input'}
          />
          {errors.email && touched.email && <div className="input-feedback">{errors.email}</div>}
        </div>
        {values.layout === 'wide' && submitFeedback(status)}
      </div>

      <div className={styles.column}>
        <div className={styles.formControl}>
          <textarea
            id="message"
            placeholder="Ihre Nachricht ..."
            aria-label="Ihre Nachricht"
            aria-required="true"
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.message && touched.message ? 'text-input error' : 'text-input'}
          />
          {errors.message && touched.message && <div className="input-feedback">{errors.message}</div>}
        </div>

        <div className={styles.acceptDatenschutz}>
          <label>
            <input
              id="acceptDatenschutz"
              name="acceptDatenschutz"
              type="checkbox"
              checked={values.acceptDatenschutz}
              onChange={handleChange}
            />
            <span>
              Ich bestätige, die{' '}
              <Link style={{ background: 'transparent' }} to="/datenschutz">
                Datenschutzbestimmungen
              </Link>{' '}
              gelesen und verstanden zu haben
            </span>
          </label>
          {errors.acceptDatenschutz && touched.acceptDatenschutz && (
            <div className="input-feedback">{errors.acceptDatenschutz}</div>
          )}
        </div>

        <div className={clsx(styles.formControl)} style={{ marginTop: 0 }}>
          <button type="submit" disabled={isSubmitting}>
            Email Senden
          </button>
        </div>

        {values.layout !== 'wide' && submitFeedback(status)}
      </div>
    </form>
  );
}

export default InnerForm;
